/*@tailwind base;*/
@tailwind components;
@tailwind utilities;

.ant-modal-wrap {
  z-index: 1301 !important;
}

.ant-dropdown {
  z-index: 1302 !important;
}

.ant-select-dropdown {
  z-index: 1303 !important;
}

.ant-picker-dropdown {
  z-index: 1304 !important;
}

.ant-popover {
  z-index: 1302 !important;
}

.ant-table-placeholder {
  z-index: 100 !important;
}

.ant-tabs-dropdown {
  z-index: 1400 !important;
}

.ant-tooltip {
  z-index: 1401 !important;
}

.ck-balloon-panel {
  z-index: 1305 !important;
}

.ant-image-preview-mask {
  z-index: 1306 !important;
}

.ant-image-preview-wrap {
  z-index: 1306 !important;
}

.ant-image-preview-operations-wrapper {
  z-index: 1307 !important;
}
